
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { GalleryItemClient } from '@/services/Services';
import axios from 'axios';
import * as OM from "@/Model";

@Options({
    components: {
    }
})
export default class GalleryImageDetailModal extends Vue {

    @Prop({
        default: () => new OM.GalleryItemVM()
    }) actualImage: OM.GalleryItemVM;
    @Prop() listaDivisa;
    @Prop() ora: string;
    @Prop() fascia: string;

    localActualImage = new OM.GalleryItemVM();
    localOra: string = "";
    localFascia: string = "";

    conta = 0;
    actualImageNumber = 0;
    actualImageIndex = 0;

    blobUrl: string = "";

    created(){
        this.localActualImage = JSON.parse(JSON.stringify(this.actualImage));
        this.localOra = this.ora;
        this.localFascia = this.fascia;

        this.contaOggetti();
        this.contaACheFotoSei();
    }

    mounted() {
        // const imageUrl = this.localActualImage.imageUrl;
        // fetch(imageUrl)
        // .then(response => response.blob())
        // .then(blob => {
        //     this.blobUrl = URL.createObjectURL(blob);
        // });
        // axios.get(imageUrl, { responseType: 'blob' })
        // .then(response => {
        //     this.blobUrl = URL.createObjectURL(response.data);
        // })
        // .catch(error => {
        //     console.error('Error downloading image:', error);
        // });
    }

    contaOggetti() {
        this.conta = 0;

        // Itera attraverso le fasce orarie
        for (var ora in this.listaDivisa)
        // Itera attraverso le fasce orarie della singola ora

        for (var fascia in this.listaDivisa[ora]) {
            // Incrementa il contatore per il numero di oggetti nella lista
            this.conta += this.listaDivisa[ora][fascia].length;
        }
    }

    contaACheFotoSei(){
        var count = 0;

        for (var ora in this.listaDivisa) {
            if (ora < this.localOra) {
                // Itera attraverso tutte le fascie dell'ora corrente
                for (var fascia in this.listaDivisa[ora]) {
                    // Incrementa il contatore per gli oggetti della fascia corrente
                    count += this.listaDivisa[ora][fascia].length;
                }
            } else if (ora === this.localOra) {
                // Itera attraverso tutte le fascie precedenti all'interno dell'ora di ricerca
                var index = 0;
                for (var fascia in this.listaDivisa[ora]) {
                    if (fascia < this.localFascia) {
                        // Incrementa il contatore per gli oggetti della fascia corrente
                        count += this.listaDivisa[ora][fascia].length;
                    } else if (fascia === this.localFascia) {
                        // Trovata la fascia di ricerca, controlla gli oggetti posizionati prima dell'elemento corrente
                        var trovato = false;
                        this.listaDivisa[ora][fascia].forEach((elemento) => {
                            if (elemento.imageUrl === this.localActualImage.imageUrl) {
                                this.actualImageIndex = index;
                                trovato = true;
                            } else if (!trovato) {
                                count++;
                                index++;
                            }
                        });
                    }
                }
            }
        }

        this.actualImageNumber = count + 1;
    }

    get isPrevDisabled(){
        var firstOra = Object.keys(this.listaDivisa)[0];
        var firstFascia = Object.keys(this.listaDivisa[firstOra])[0];

        return firstOra == this.localOra && this.localFascia == firstFascia && this.actualImageIndex == 0;
    }

    get isNextDisabled(){
        var ore = Object.keys(this.listaDivisa);
        var lastOra = ore[ore.length - 1];

        var fasce = Object.keys(this.listaDivisa[lastOra])
        var lastFascia = fasce[fasce.length - 1];

        var lastIndex = this.listaDivisa[lastOra][lastFascia].length - 1

        return lastOra == this.localOra && this.localFascia == lastFascia && this.actualImageIndex == lastIndex;
    }

    prev(){
        if(this.actualImageIndex > 0){
            this.actualImageIndex -= 1;
            this.actualImageNumber -= 1;

            this.localActualImage = this.listaDivisa[this.localOra][this.localFascia][this.actualImageIndex];

            return;
        }

        var actualOraKeys = Object.keys(this.listaDivisa[this.localOra]);
        var fasciaIndex = actualOraKeys.indexOf(this.localFascia);

        if(fasciaIndex != 0){
            this.localFascia = actualOraKeys[fasciaIndex - 1];
            this.actualImageIndex = this.listaDivisa[this.localOra][this.localFascia].length - 1;
            this.actualImageNumber -= 1;
            this.localActualImage = this.listaDivisa[this.localOra][this.localFascia][this.actualImageIndex];
            
            return;
        }

        var totalHours = Object.keys(this.listaDivisa);
        var oraIndex = totalHours.indexOf(this.localOra);

        this.localOra = totalHours[oraIndex - 1];

        var newFasciaKeys = Object.keys(this.listaDivisa[this.localOra]);
        this.localFascia = newFasciaKeys[newFasciaKeys.length - 1];

        this.actualImageIndex = this.listaDivisa[this.localOra][this.localFascia].length - 1;
        this.actualImageNumber -= 1;
        this.localActualImage = this.listaDivisa[this.localOra][this.localFascia][this.actualImageIndex];
    }

    next(){
        var lengthFascia = this.listaDivisa[this.localOra][this.localFascia].length;
        if(this.actualImageIndex < lengthFascia - 1){
            this.actualImageIndex += 1;
            this.actualImageNumber += 1;

            this.localActualImage = this.listaDivisa[this.localOra][this.localFascia][this.actualImageIndex];

            return;
        }

        var actualOraKeys = Object.keys(this.listaDivisa[this.localOra]);
        var fasciaIndex = actualOraKeys.indexOf(this.localFascia);

        if(fasciaIndex != actualOraKeys.length - 1){
            this.localFascia = actualOraKeys[fasciaIndex + 1];
            this.actualImageIndex = 0;
            this.actualImageNumber += 1;
            this.localActualImage = this.listaDivisa[this.localOra][this.localFascia][this.actualImageIndex];
            
            return;
        }

        var totalHours = Object.keys(this.listaDivisa);
        var oraIndex = totalHours.indexOf(this.localOra);

        this.localOra = totalHours[oraIndex + 1];

        var newFasciaKeys = Object.keys(this.listaDivisa[this.localOra]);
        this.localFascia = newFasciaKeys[0];
        this.actualImageIndex = 0;
        this.actualImageNumber += 1;
        this.localActualImage = this.listaDivisa[this.localOra][this.localFascia][this.actualImageIndex];
    }

    // async downloadImage() {
    //     const link = document.createElement('a');
    //     link.href = this.localActualImage.imageUrl;
    //     link.download = 'Aprilia_' + this.localActualImage.capturedOn + ".jpg";
    //     link.target = '_blank';
    //     link.click();
    // }

    downloadImage() {
        GalleryItemClient.downloadImage(this.localActualImage.attachmentIdentifier)
        .then(x => {

        })
    }

    shareImage() {
        if (navigator.share) {
            navigator.share({
                title: 'Aprilia All Stars',
                text: 'Misano - 27 Maggio 2023',
                url: this.localActualImage.imageUrl
            })
            .then(() => 
                GalleryItemClient.shareImage(this.localActualImage.attachmentIdentifier)
                .then(x => {
                    console.log('Condivisione riuscita.')
                })
            )
            .catch((error) => console.log('Errore durante la condivisione:', error));
        } 
        else
            console.log('La condivisione non è supportata nel tuo browser.');
    }

}
