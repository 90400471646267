
import { Options, Vue } from 'vue-class-component';
import GalleryImageDetailModal from '../modals/galleryImageDetailModal.vue';
import * as OM from '@/Model';
import { AdminClient, GalleryItemClient } from '@/services/Services';
import { getUniversalDate } from '@/utils';
import { Watch } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class Gallery extends Vue {

    list: OM.GalleryItemVM[] = [];
    listDivisa = {};

    selectedHourRange: string = "";
    hourRanges: OM.TextIdentifier[] = [];

    isPublished: boolean = false;
    isMounted = false;
    
    @Watch("selectedHourRange")
    hourRangeChanged(){
        if(!this.selectedHourRange || !this.isMounted)
            return;

        // Ottenere il riferimento all'elemento del div tramite l'ID
        var divElement = document.getElementById(this.selectedHourRange);

        // Eseguire lo scroll della vista all'interno del div
        divElement.scrollIntoView();
    }

    created() {
        Promise.all([
            AdminClient.getGalleryStatus(),
            GalleryItemClient.getGallery()
        ])
        .then(xs => {
            this.isPublished = xs[0];
            this.list = xs[1];
            this.impostaListDivisa();
            this.impostaFasceOrarie();
        })
    } 

    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, 1000);
    }

    impostaListDivisa(){
        this.listDivisa = {};

        this.list.forEach(element => {
            var orario = getUniversalDate(element.capturedOn);
            var oraInt = orario.getHours();
            var minuti = orario.getMinutes();
            var segmento = Math.floor(minuti / 15); // Calcolo del segmento di 15 minuti

            var ora = oraInt.toString().padStart(2, '0');

            // Verifica se la fascia oraria esiste già nell'oggetto fasceOrarie
            if (!this.listDivisa[ora]) {
                // Se la fascia oraria non esiste, creazione di un nuovo oggetto per i segmenti di 15 minuti
                this.listDivisa[ora] = {};
            }

            var fasciaSegmento = this.getFasciaOraria(segmento);

            if (!this.listDivisa[ora][fasciaSegmento]) {
                // Se la fascia oraria non esiste, creazione di un nuovo oggetto per i segmenti di 15 minuti
                this.listDivisa[ora][fasciaSegmento] = [];
            }

            // Aggiunta dell'elemento corrente all'array del segmento di 15 minuti corrispondente
            this.listDivisa[ora][fasciaSegmento].push(element);
        });
    }

    impostaFasceOrarie(){
        this.hourRanges = [];

        var oraKeys = Object.keys(this.listDivisa);
        
        oraKeys.forEach( ora => {
            var fasciaKeys = Object.keys(this.listDivisa[ora]);

            fasciaKeys.forEach( fascia => {
                
                var hour = ora + ":" + fascia;

                this.hourRanges.push({
                    identifier: hour,
                    text: hour,
                })
            })
        })

        if(this.hourRanges.length > 0){
            var firstHour = this.hourRanges[0].identifier;
            var hour = firstHour.split(":")[0];
            
            var found = this.hourRanges.find( x => x.identifier == hour + ":00");
            if(found)
                this.selectedHourRange = found.identifier;
            else 
                this.selectedHourRange = this.hourRanges[0].identifier;
        }
    }

    getFasciaOraria(segmento) {
        switch (segmento) {
            case 0:
            return '00';
            case 1:
            return '15';
            case 2:
            return '30';
            case 3:
            return '45';
            default:
            return 'Invalid';
        }
    }

    openImageDetail(image: OM.GalleryItemVM, ora: string, fascia: string) {
        this.$opModal.show(GalleryImageDetailModal, {
            actualImage: image,
            listaDivisa: this.listDivisa,
            ora: ora,
            fascia: fascia
        })
    }

}
